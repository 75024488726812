<template>
  <div class="bg-white flex h-full overflow-hidden" style="flex-direction: column;">
    <div class="flex items-center mb-2">
      <div>
        <a-select v-model="formData.adcode" style="width:100px;"
                  placeholder="选择城市"
                  @change="handlerCityChange">
          <a-select-option v-for="(item, index) in cityList"
                 :key="index"
                 :value="item.adcode">{{ item.name }}</a-select-option>
        </a-select>
        <!-- <a-select v-model="formData.outlets_city_adcode" style="width:100px;"
                placeholder="选择城市"
                @change="handlerManageAdcodeChange">
          <a-select-option v-for="(item, index) in manageCityList"
                 :key="index"
                 :value="item.adcode">{{ item.name }}</a-select-option>
        </a-select> -->
        <a-select v-model="formData.status" class="ml-2" style="width:130px;"
                  @change="handlerStatusChange">
          <a-select-option value="">未发布/营业中</a-select-option>
          <a-select-option value="3">营业中</a-select-option>
        </a-select>
        <a-select v-model="formData.include_pending" class="ml-2" style="width:150px;"
                  @change="handlerIncludePendingChange">
          <a-select-option value="1">全部</a-select-option>
          <a-select-option value="">已设置配送范围</a-select-option>
        </a-select>
        <a-select v-model="search_outlets_id" class="ml-2" style="width:220px;"
                  show-search
                  placeholder="请输入网点名称"
                  :filter-option="false"
                  :not-found-content="null"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  @search="handlerSearch"
                  @change="handlerSearchOutletsChanged">
          <a-select-option v-for="d in search_outletslist"
                           :key="d.outlets_id">
            {{ d.outlets_name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex items-center ml-3" style="flex:1;">
        <a-select
          show-search
          v-model="search_address"
          placeholder="输入收货地址"
          style="flex:1;width:0;"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handlerAddressSearch"
          @change="handlerAddressChanged"
        >
          <a-select-option 
              v-for="d in search_address_list" :value="d.id" :key="d.id"
            >{{ d.title }}<span class="addr-region">{{ d.region }}</span>
          </a-select-option>
        </a-select>
        <!-- <a-button class="ml-2" type="primary" @click="handlerSearch">搜地址</a-button> -->
      </div>
    </div>
    

    <div class="relative flex-1" style="overflow:hidden;">
      <!-- <div>操作说明：点击新增后，在地图上点击画线，双击画线完毕后输入关键信息保存。</div>
      <div> 点选某一个点，展示配送范围，点击某配送范围，可直接编辑。</div>
      <div>
        绘制 鼠标左键点击及移动即可绘制图形<br />
        结束绘制 鼠标左键双击即可结束绘制折线、多边形、圆形，多边形会自动闭合<br />
        单选 鼠标左键点击图形<br />
        中断 绘制或编辑过程中按下esc键可中断该过程<br />
      </div>-->
      <div id="map-container" class="map-container">
        <div id="shop_map_container" class="map-class"></div>
      </div>
      <div class="tool-control">
        <template v-if="isActive">
          <a-button class="ml-2" :disabled="!this.selectedAreaId"
                    @click="handlerCancel">取消选中</a-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCityList,
  getFinanceCityList,
  getRegionList
} from "@/api/outlets"
import { checkAdcodes } from "@/utils/address.js"
import { getCityLatLng, getMapPointList, IsPtInPoly } from "@/utils/tMap.js"

let TMap = window.TMap

export default {
  components: {  },
  data () {
    return {
      relatedCityList: [
        {
          name: "宝鸡",
          adcode: "610300000000",
          related_adcode: "610100000000"
        },{
          name: "渭南",
          adcode: "610500000000",
          related_adcode: "610100000000"
        },{
          name: "咸阳",
          adcode: "610400000000",
          related_adcode: "610100000000"
        },{
          name: "廊坊",
          adcode: "131000000000",
          related_adcode: "110100000000"
        },{
          name: "绵阳",
          adcode: "510700000000",
          related_adcode: "510100000000"
        },{
          name: "德阳",
          adcode: "510600000000",
          related_adcode: "510100000000"
        // },{
        //   name: "内江",
        //   adcode: "511000000000",
        //   related_adcode: "510100000000"
        // },{
        //   name: "自贡",
        //   adcode: "510300000000",
        //   related_adcode: "510100000000"
        // },{
        //   name: "眉山",
        //   adcode: "511400000000",
        //   related_adcode: "510100000000"
        },
        
        // 德州   371400000000 归谁？
        // 苏州   320500000000 财务城市是 昆山
      ],

      formData: {
        adcode: undefined,
        outlets_city_adcode: undefined,
        status: "3",
        include_pending: "",
        is_delivery: '',
      },

      cityList: [],
      manageCityList: [],
      isCityManage: false,
      dataList: [],
      searchKeyWord: "",

      shopStyle: {
        // 'shopMarker': new TMap.MarkerStyle({
        //   width: 30,
        //   height: 34,
        //   src: require("../../assets/icon-shop.png"), //图片路径
        //   anchor: { x: 15, y: 34 },
        // }),
        // 'duduMarker': new TMap.MarkerStyle({
        //   width: 30,
        //   height: 39,
        //   src: require("../../assets/icon-peisong.png"), //图片路径
        //   anchor: { x: 15, y: 39 },
        // }),
      },

      markerList: [],
      userAdress: null,

      // 地图操作对象
      map: null,
      editor: null,
      addEditor: null,
      marker: null,

      selectedAreaId: null,
      isActive: false, // 是否激活
      multipolygon: null,  // 存放图层
      infoWindow: null, //信息窗口
      // 搜门店
      search_outletslist: [],
      search_outlets_id: undefined,
      
      // 搜地址
      search_address: undefined,
      addressList: [],
      search_address_list: null,

      initTimer: null,
      inited: false,
    }
  },
  async mounted () {
    // 如果是城市市场账号
    const list = this.$store.getters['authCityList']
    if(list && list.length) {
      this.manageCityList = list
      this.isCityManage = true
    }
    
    await this.initCityList()
    // await this.initFinanceCityList()
    // this.handlerManageAdcodeChange()

    // setTimeout(this.init, 1000)
  },
  methods: {

    // 对外的城市列表
    async initCityList () {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },
    // 内部管理的城市列表
    async initFinanceCityList () {
      const list = this.$store.getters['authCityList']
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      }
    },
    

    // 刷新区域数据
    async refreshRegion () {
      // 重置状态、关闭内容
      this.markerList = []
      this.isActive = false
      this.selectedAreaId = null

      if(this.infoWindow){
        this.infoWindow.close();
      }
      if (this.multipolygon) {
        this.multipolygon.setMap(null)
        this.multipolygon = null
      }
      if (this.editor) {
        this.editor.setMap(null)
        this.editor = null
      }
      if (this.addEditor) {
        this.addEditor.setMap(null)
        this.addEditor = null
      }
      
      // 请求新数据
      // const { data, code } = await getRegionList(this.formData)
      // if (code == 0) {
      //   this.baseList = data.list
      //   this.basePendingList = data.pending_list
      //   let dataList = data.list.concat(data.pending_list).map(item=>{
      //     // item.type = formatOutletsType(item.outlets_type)
      //     if(item.id){
      //       item.tmp_id = item.outlets_id+"-"+item.id
      //     }else{
      //       item.tmp_id = item.outlets_id
      //     }
      //     return item
      //   })
      //   this.dataList = dataList
      // }
      const list = await this.getRelatedRegionList()
      this.dataList = list
    },
    async getRelatedRegionList(){
      let dataList = []
      const params = {
        ...this.formData
      }

      // 如果是城市
      let needReq = true
      if(this.isCityManage){
        params.outlets_city_adcode = this.formData.adcode
        params.adcode = ""
        let hasCityAuth = this.manageCityList.some(el=>{
          const adcode_result = checkAdcodes(el.adcode, this.formData.adcode)
          return (adcode_result == 0 || adcode_result == 2)
        })
        if(!hasCityAuth){
          needReq = false
          console.log("城市账号 没有权限", this.formData.adcode)
        }
      }

      if(needReq){
        const { data, code } = await getRegionList(params)
        if (code == 0) {
          dataList = data.list.concat(data.pending_list || []).map(item=>{
            if(item.id){
              item.tmp_id = item.outlets_id+"-"+item.id
            }else{
              item.tmp_id = item.outlets_id
            }
            return item
          })
        }
      }

      const relatedCity = this.relatedCityList.find(el=>el.adcode == this.formData.adcode)
      console.log("关联城市", relatedCity)
      if(relatedCity){
        const params2 = {
          ...this.formData,
          adcode: relatedCity.related_adcode
        }
        let needReqRelated = true
        if(this.isCityManage){
          params2.outlets_city_adcode = relatedCity.related_adcode
          params2.adcode = ""
          let hasRelatedCityAuth = this.manageCityList.some(el=>{
            const adcode_result = checkAdcodes(el.adcode, relatedCity.related_adcode)
            return (adcode_result == 0 || adcode_result == 2)
          })
          if(!hasRelatedCityAuth){
            needReqRelated = false
            console.log("城市账号 没有权限", relatedCity.related_adcode)
          }
        }
        if(needReqRelated){
          const res2 = await getRegionList(params2)
          if (res2.code == 0) {
            dataList = dataList.concat(res2.data.list).concat(res2.data.pending_list || []).map(item=>{
              if(item.id){
                item.tmp_id = item.outlets_id+"-"+item.id
              }else{
                item.tmp_id = item.outlets_id
              }
              return item
            })
          }
        }
      }
      
      return dataList
    },

    initMarkerList(){
      // 生成marker数据
      let markerList = []
      this.dataList.map(item => {
        let marker = markerList.find(el=>el.outlets_id==item.outlets_id)
        if(!marker){
          marker = {
            // "id": item.id,
            // "tmp_id": item.tmp_id,
            "styleId": item.outlets_type === 1 ? 'shopMarker' : 'duduMarker',
            "outlets_id": item.outlets_id,
            "outlets_name": item.outlets_name,
            "position": new TMap.LatLng(item.latitude, item.longitude),
            "properties": {
              "type": "outlets",
              "title": item.outlets_name,
              "address": item.outlets_address,
              "region_ids": []
            }
          }
          markerList.push(marker)
        }
        if(item.id){
          marker.properties.region_ids.push(item.id)
        }
      })
      this.markerList = markerList

      // 渲染显示
      if(this.map){
        if(this.marker){
          this.marker.setMap(null)
        }
        this.marker = new TMap.MultiMarker({
          id: "shop_map_container_layer",
          map: this.map,
          styles: this.shopStyle,
          geometries: this.markerList,
          enableBubble: false,
        })

        // 监听标注点击事件
        const _this = this
        this.marker.on("click", function (evt) {
          evt.originalEvent.stopPropagation()
          _this.popInfoWindow(evt.geometry)
        })

        // 把所有marker囊括到地图可视区
        let bounds = new TMap.LatLngBounds();
        this.markerList.forEach(function (item) {
          if (bounds.isEmpty() || !bounds.contains(item.position)) {
            bounds.extend(item.position);
          }
        })
        // 设置地图可视范围
        this.map.fitBounds(bounds, {
          padding: 40 // 自适应边距
        });
      }
    },

    onChooseUserAddress(addr){
      if(!this.map) return
      addr.random_id =  addr.id + "_" + Math.round(Math.random()*10000000)

      // 移除旧地址
      if(this.userAdress){
        if(this.marker){
          this.marker.remove(this.userAdress.random_id)
        }
      }

      // 地图移动
      const center = new TMap.LatLng(addr.location.lat, addr.location.lng)
      this.map.easeTo({
        center: center,
        zoom: 14,
      })

      setTimeout(this.addAddressMarker, 600, addr)
    },
    addAddressMarker(addr){
      // 添加新地址
      const areaList = this.figureDeliveryRegion({
        lat: addr.location.lat, 
        lng: addr.location.lng
      })
      const address_marker = {
        "id": addr.random_id,
        "styleId": 'addrMarker',
        "position": new TMap.LatLng(addr.location.lat, addr.location.lng),
        "properties": {
          "type": "user",
          "title": addr.title,
          "address": addr.address,
          "region_ids": areaList.map(el=>el.id),
          "region_list": areaList,
        }
      }
      if(this.marker){
        this.userAdress = addr
        this.marker.add(address_marker)
        this.popUserWindow(address_marker)
      }
      
      // if(areaList.length){
      //   this.popUserWindow(address_marker, areaList)
      // }else{
      //   this.$message.info('超出配送范围')
      // }
      if(!areaList.length){
        this.$message.info('超出配送范围')
      }
    },

    renderRegion(){
      if (this.multipolygon) {
        // this.multipolygon.remove(this.rowSelection.selectedRowKeys)
        this.multipolygon.setMap(null)
        this.multipolygon = null
      }
      if (this.editor) {
        this.editor.setMap(null)
        this.editor = null
      }
      if (this.addEditor) {
        this.addEditor.setMap(null)
        this.addEditor = null
      }
      this.selectedAreaId = null

      // 全选
      this.drawArea()
    },

    handlerSearch (kw) {
      let tmp = []
      if (kw) {
        tmp = this.dataList.filter(item => {
          return (item.name||"").includes(kw) || item.outlets_address.includes(kw) || item.outlets_name.includes(kw)
        })
      }
      let list = []
      tmp.map(el=>{
        if(!list.some(o=>o.outlets_id == el.outlets_id)){
          list.push(el)
        }
      })
      this.search_outletslist = list
    },
    handlerSearchOutletsChanged(){
      if(this.search_outlets_id){
        this.popInfoWindow(null, this.search_outlets_id)
      }
    },

    async handlerCityChange () {
      if(!this.formData.adcode) return

      // 重置状态、关闭内容
      if(this.marker){
        this.marker.setMap(null)
      }
      
      // 获取新数据
      await this.refreshRegion()
      // 初始化地图
      await this.initMap(this.formData.adcode)
      // 给地图添加网点icon
      this.initMarkerList()
      this.renderRegion()
    },
    async handlerManageAdcodeChange () {
      if(!this.formData.outlets_city_adcode) return

      // 重置状态、关闭内容
      if(this.marker){
        this.marker.setMap(null)
      }
      
      // 获取新数据
      await this.refreshRegion()
      // 初始化地图
      await this.initMap(this.formData.outlets_city_adcode)
      // 给地图添加网点icon
      this.initMarkerList()
      this.renderRegion()
    },
    async handlerStatusChange(){
      await this.refreshRegion()
      this.initMarkerList()
      this.renderRegion()
    },
    async handlerIncludePendingChange(){
      await this.refreshRegion()
      this.initMarkerList()
      this.renderRegion()
    },

    async initMap (city_adcode) {
      TMap = window.TMap
      if(typeof TMap == "undefined") {
        this.$message.warning('地图初始化失败')
        return
      }
      if(!this.inited){
        this.inited = true
        this.shopStyle['shopMarker'] = new TMap.MarkerStyle({
          width: 30,
          height: 34,
          src: require("../../assets/icon-shop.png"), //图片路径
          anchor: { x: 15, y: 34 },
        })
        this.shopStyle['duduMarker'] = new TMap.MarkerStyle({
          width: 30,
          height: 39,
          src: require("../../assets/icon-peisong.png"), //图片路径
          anchor: { x: 15, y: 39 },
        })
        this.shopStyle['addrMarker'] = new TMap.MarkerStyle({
          width: 27,
          height: 36,
          src: require("../../assets/icon-user.png"), //图片路径
          anchor: { x: 14, y: 36 },
        })
      } 

      // 计算地图中心点
      let center_lat, center_lng
      if (this.dataList.length > 0) { // 有网点列表：地图就囊括所有网点
        let totalLat = 0
        let totalLng = 0
        this.dataList.map(d => {
          totalLat += d.latitude
          totalLng += d.longitude
        })
        center_lat = totalLat / this.dataList.length
        center_lng = totalLng / this.dataList.length
      } else { // 没有网点列表：通过城市定位显示地图
        const { status, result } = await getCityLatLng(city_adcode)
        if (status == 0 && result[0].length) {
          let totalLat = 0
          let totalLng = 0
          result[0].map(d => {
            totalLat += d.location.lat
            totalLng += d.location.lng
          })
          center_lat = totalLat / result[0].length
          center_lng = totalLng / result[0].length
        }
      }
      // 设置中心点坐标
      let center = new TMap.LatLng(center_lat, center_lng)

      // 初始化地图
      if (!this.map) {
        this.map = new TMap.Map(
          document.getElementById("shop_map_container"),
          {
            center: center,
            zoom: 12,
          }
        )
      }
      // 移动到中心点
      this.map.panTo(center)

      // 初始化infoWindow*********************************
      if(!this.infoWindow){
        this.infoWindow = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(center_lat, center_lng),
          offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
        });
        this.infoWindow.close();//初始关闭信息窗关
      }
    },

    popInfoWindow(geometry, outlets_id){
      if(geometry && geometry.properties.type == "user"){
        this.popUserWindow(geometry)
        return
      }

      // 关闭旧弹窗
      if(this.infoWindow){
        this.infoWindow.close();
      }
      // 如果点击配送范围
      if(!geometry && outlets_id){
        geometry = this.markerList.find(m=>m.outlets_id==outlets_id)
      }
      if(!geometry) return

      const _this = this
      if(geometry.properties.type == "outlets"){
      // 查看网点
        // 设置infoWindow
        this.infoWindow.open(); //打开信息窗
        this.infoWindow.setPosition(geometry.position);//设置信息窗位置

        const hasRegions = geometry.properties.region_ids && geometry.properties.region_ids.length
        this.infoWindow.setContent(`
            <div style="text-align: left;white-space: normal;max-width: 350px;">
              <div><b>${geometry.properties.title}</b></div>
              <div>${geometry.properties.address}</div>
              <div style="margin-top:10px;" >
                <a style="${hasRegions?'':'display:none;'}" onclick="_mapShowPoiRegion()">查看范围</a>
              </div>
            </div>`);

        // 加事件：点击查看某网点的配送范围
        window._mapShowPoiRegion = (() => {
          _this.infoWindow.close();
          _this.isActive = false
          if (_this.editor) {
            _this.editor.setMap(null)
          }
          if (_this.multipolygon) {
            _this.multipolygon.setGeometries([])
          }

          const outlets_id = geometry.outlets_id
          if(outlets_id){
            _this.focusRegions(geometry.properties.region_ids)
            _this.popInfoWindow(null, geometry.outlets_id)
          } 
        })
      }
    },

    popUserWindow(geometry){
      // 关闭旧弹窗
      if(this.infoWindow){
        this.infoWindow.close();
      }

      this.infoWindow.open(); //打开信息窗
      this.infoWindow.setPosition(geometry.position);//设置信息窗位置

      const area_list = geometry.properties.region_list
      const hasRegions = area_list && area_list.length
      let tmp_html = ""
      if(hasRegions){
        const area_tmp_html = area_list.map(el=>{
          return `<div>网点：${el.outlets_name}，运费：￥${el.freight}<a style="margin-left:20px;" onclick="_mapShowUserArea(${el.id})">查看</a></div>`
        }).join("")
        tmp_html = `<div style="margin-top:10px;border-top:1px solid #ccc;padding-top:6px;" >
              ${area_tmp_html}
            </div>`
      }

      this.infoWindow.setContent(`
          <div style="text-align: left;white-space: normal;max-width: 350px;">
            <div><b>${geometry.properties.title}</b></div>
            <div>${geometry.properties.address}</div>
            ${tmp_html}
          </div>`);

      // 加事件：点击查看某网点的配送范围
      const _this = this
      window._mapShowUserArea = ((region_id) => {
        _this.infoWindow.close();
        _this.isActive = false
        if (_this.editor) {
          _this.editor.setMap(null)
        }
        if (_this.multipolygon) {
          _this.multipolygon.setGeometries([])
        }
        _this.focusRegions([region_id])
      })
    },

    //********************* 地图上区域操作部分   ************************************************ */
    // 将所有选中行的区域画到地图上
    drawArea (outlets_id) {
      let list = this.dataList
      if(outlets_id){
        list = this.dataList.filter(item=>{
          return item.outlets_id==outlets_id
        })
      }

      let regionList = []
      list.map(el=>{
        if(!el.id) return
        let paths = []
        el.region.split(',').map(item => {
          paths.push(new TMap.LatLng(item.split(' ')[1], item.split(' ')[0]))
        })
        regionList.push({
          tmp_id: el.tmp_id,
          id: el.id,
          outlets_id: el.outlets_id,
          name: el.name,
          freight: el.freight,
          paths: paths,
          styleId: 'normal'
        })
      })

      if (this.multipolygon) {
        this.multipolygon.setGeometries(regionList)
      } else {
        this.multipolygon = new TMap.MultiPolygon({
          map: this.map,
          id: 'multipolygon',
          styles: {
            // 'normal': new TMap.PolygonStyle({
            //   color: 'rgba(0, 0, 255, 0.3)'
            // }),
            'selected': new TMap.PolygonStyle({
              color: 'rgba(0,255,193,0.6)'
            }),
            // 'highlight': new TMap.PolygonStyle({
            //   color: 'rgba(255, 255, 0, 0.6)'
            // })
          },
          geometries: regionList,
          selectedStyleId: 'highlight',
          enableBubble: false,
        })
        
        this.multipolygon.on('click', this.handlerAreaClick)
      }
    },

    // 点击地图上的区域执行事件
    handlerAreaClick (e) {
      this.$message.info("￥"+e.geometry.freight+"，"+e.geometry.name)

      e.originalEvent.stopPropagation()

      this.focusRegions([e.geometry.id])
      this.popInfoWindow(null, e.geometry.outlets_id)
    },

    focusRegions(list){
      list = list || []
      let regionList = []
      this.dataList.filter(item=>item.id).map(el => {
        let paths = []
        el.region.split(',').map(item => {
          paths.push(new TMap.LatLng(item.split(' ')[1], item.split(' ')[0]))
        })
        let isFocus = list && list.includes(el.id)
        regionList.push({
          tmp_id: el.tmp_id,
          id: el.id,
          outlets_id: el.outlets_id,
          name: el.name,
          freight: el.freight,
          paths: paths,
          styleId: isFocus ? 'selected' : 'normal'
        })
      })
      
      if(this.multipolygon){
        this.multipolygon.updateGeometries(regionList)
      }
      this.selectedAreaId = list.join(",")
      this.isActive = true
      this.$forceUpdate()

      if (this.infoWindow) {
        this.infoWindow.close();
      }
      if (this.editor) {
        this.editor.enable()
      }
    },

    // 搜索地址
    async handlerAddressSearch(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.search_address_list = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    // 选择搜索结果
    async handlerAddressChanged(value) {
      const item = this.search_address_list.find((item) => item.id === value)
      if(!item) return

      // this.search_address = (item.address || '') + (item.title || '')
      this.search_address = item.title || item.address
      this.search_address_lat = item.location.lat
      this.search_address_lng = item.location.lng

      // 移动地图、添加marker、提示运费
      // const city = this.manageCityList.find(el=>{
      //   const adcode_result = checkAdcodes(el.adcode, item.adcode)
      //   return (adcode_result == 0 || adcode_result == 2)
      // })
      // if(!city){
      //   this.$message.info('该地址所在城市没有服务')
      //   return
      // }
      // if(this.formData.outlets_city_adcode != city.adcode){
      //   this.formData.outlets_city_adcode = city.adcode
      //   await this.handlerManageAdcodeChange()
      // }
      let city = this.cityList.find(el=>{
        const adcode_result = checkAdcodes(el.adcode, item.adcode)
        return (adcode_result == 0 || adcode_result == 2)
      })
      // 如果没有城市、再查查有没有关联的父级城市（廊坊找北京）
      if(!city){
        // {
        //   name: "宝鸡",
        //   adcode: "610300000000",
        //   related_adcode: "610100000000"
        // },{
        const relatedCity = this.relatedCityList.find(el=>{
          const adcode_result = checkAdcodes(el.adcode, item.adcode)
          return (adcode_result == 0 || adcode_result == 2)
        })
        if(relatedCity){
          city = {
            adcode: parseInt(relatedCity.related_adcode)
          }
        }
      }
      if(!city){
        this.$message.info('该地址所在城市没有服务')
        return
      }
      if(this.formData.adcode != city.adcode){
        this.formData.adcode = city.adcode
        await this.handlerCityChange()
      }
      this.onChooseUserAddress(item)
    },

    // 配送范围
    figureDeliveryRegion(poi) {
      const list = this.dataList.filter(el=>{
        if(el.region){
          let tmp = el.region.split(",");
          let pointList = tmp.map(el => {
            let t = el.split(' ');
            return {
              latitude: Number(t[1]),
              longitude: Number(t[0])
            }
          })
          if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
            return true
          }
        }
      }).map(el=>{
        return {
          ...el
        }
      })
      
      const result_list = []
      list.sort((a, b) => a.freight - b.freight).map(el=>{
        if(!result_list.some(rg=>rg.outlets_id==el.outlets_id)){
          result_list.push(el)
        }else{
          console.log("重复region")
        }
      });

      return result_list
    },

    handlerCancel(){
      this.focusRegions()
    },
  },

}
</script>
<style lang="less">
</style>
<style lang="less" scoped>
.addr-region{
  color: #aaa;
  margin-left: 10px;
}
.map-container {
  background-color: #f2f2f2;
  // width: 100%;
  flex: 1 0 0;
  // height: 83vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  .map-lola {
    height: 35px;
    display: flex;
  }
  .map-class {
    width: 100%;
    // height: 83vh;
    height: 100%;
  }
}
.tool-control {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 1000;
}
</style>