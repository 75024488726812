import { jsonp } from 'vue-jsonp'
import config from '@/utils/config'

/**
 * 根据关键字，搜索POI点
 * @param {*} keyword 
 * @returns 
 */
export function getMapPointList (keyword) {
  return new Promise((resolve, reject) => {
    if(!keyword){
      resolve({
        status: 0,
        data: []
      })
      return
    }
    const url = config.MAP_API_DOMAIN + '/ws/place/v1/suggestion/'
    jsonp(url, {
      key: config.MAP_KEY,
      // region: ,
      keyword: keyword,
      output: "jsonp"
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
// export function getMapPointList (keyword, city='北京') {
//   return new Promise((resolve, reject) => {
//     const url = 'https://apis.map.qq.com/ws/place/v1/search'
//     jsonp(url, {
//       key: MAP_KEY,
//       boundary: `region(${city})`,
//       keyword: keyword,
//       output: "jsonp"
//     }).then(res => {
//       resolve(res)
//     }).catch(err => {
//       reject(err)
//     })
//   })
// }

/**
 * 根据经纬度反查adcode
 * @param {*} lat 
 * @param {*} lng 
 * @returns 
 */
export function getAdcode (lat, lng) {

  let url = config.MAP_API_DOMAIN + '/ws/geocoder/v1';
  let locationdata = lat + ',' + lng //纬度，经度
  return jsonp(url, {
    key: config.MAP_KEY,
    output: 'jsonp',
    location: locationdata
  })
    .then(json => {
      return json //附近街道地址信息
    })
    .catch(err => {
      console.log(err)
    })
}

/**
 * 城市 --> 经纬度
 * @param {*} lat 
 * @param {*} lng 
 * @returns 
 */
export function getCityLatLng (city_adcode) {
  city_adcode = String(city_adcode).slice(0, 6)
  let url = config.MAP_API_DOMAIN + '/ws/district/v1/getchildren';
  return jsonp(url, {
    key: config.MAP_KEY,
    id: city_adcode,
    output: 'jsonp',
  })
    .then(json => {
      return json //附近街道地址信息
    })
    .catch(err => {
      console.log(err)
    })
}



/**
* @a

/**
* @aLat {float} 经度 
* @aLon {float} 纬度 
* @pointList {[]} 多边形点的顺序需根据顺时针或逆时针，不能乱 
*/
export function IsPtInPoly(aLat, aLon, pointList) {
  let iSum = 0
  let iCount = pointList.length
  if (iCount < 3) {
    return false
  }

  let pLat1, pLon1, pLat2, pLon2
  for (let i = 0; i < iCount; i++) {
    pLat1 = pointList[i].latitude
    pLon1 = pointList[i].longitude
    if (i == iCount - 1) {
      pLat2 = pointList[0].latitude
      pLon2 = pointList[0].longitude
    } else {
      pLat2 = pointList[i + 1].latitude
      pLon2 = pointList[i + 1].longitude
    }
    if (((aLat >= pLat1) && (aLat < pLat2)) || ((aLat >= pLat2) && (aLat < pLat1))) {
      if (Math.abs(pLat1 - pLat2) > 0) {
        var pLon = pLon1 - ((pLon1 - pLon2) * (pLat1 - aLat)) / (pLat1 - pLat2);
        if (pLon < aLon) {
          iSum += 1
        }
      }
    }
  }

  if (iSum % 2 != 0) {
    return true
  } else {
    return false
  }
}
 


/**
 * 根据所传两点经纬度，算出距离
 * @param {*} la1 
 * @param {*} lo1 
 * @param {*} la2 
 * @param {*} lo2 
 */
export function getDistance(la1, lo1, la2, lo2) {
  var La1 = la1 * Math.PI / 180.0;
  var La2 = la2 * Math.PI / 180.0;
  var La3 = La1 - La2;
  var Lb3 = lo1 * Math.PI / 180.0 - lo2 * Math.PI / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(La3 / 2), 2) + Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)));
  s = s * 6378.137; //地球半径
  s = Math.round(s * 10000) / 10000;
  return s;
}


// 补全adcode
export function completeAdcode(adcode, num) {
  if(!adcode) return ""
  num = num || 12
  let r = String(adcode)
  while(r.length < num){
    r = r + "0"
  }
  return r
}