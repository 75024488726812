
// adcode1 是 adcode2：父级：2
// adcode1 是 adcode2：子级：1
// adcode1 是 adcode2：相等：0
// 其他：-1
export function checkAdcodes(adcode1, adcode2) {
    let ad1 = _getLiteAdcode(adcode1)
    let ad2 = _getLiteAdcode(adcode2)
    // 不能为空
    if(!ad1.length || !ad2.length) return -1;

    if(ad1.length > ad2.length){
        if(ad1.indexOf(ad2) == 0) return 1;
    }else if(ad1.length < ad2.length){
        if(ad2.indexOf(ad1) == 0) return 2;
    }else{
        if(ad1 == ad2) return 0;
    }
    return -1;
}

function _getLiteAdcode(adcode){
    adcode = String(adcode || "").replace(/0*$/, "");
    // 空
    if(adcode.length == 0) return adcode;
    // 省
    while(adcode.length < 2){
        adcode = adcode + "0";
    }
    if(adcode.length == 2) return adcode;
    // 省市
    while(adcode.length < 4){
        adcode = adcode + "0";
    }
    if(adcode.length == 4) return adcode;
    // 省市区县
    while(adcode.length < 6){
        adcode = adcode + "0";
    }
    if(adcode.length == 6) return adcode;
    // 省市区县街道
    while(adcode.length < 9){
        adcode = adcode + "0";
    }
    if(adcode.length == 9) return adcode;
    // 其他
    return adcode;
}